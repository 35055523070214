@import "../pages/app";
@import "../components/date";

.hero-home {
    height: calc-percentage(442px, 1440px, vw);
    max-height: 442px;

    .img-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: calc-percentage(442px, 1440px, vw);
        max-height: 442px;
        overflow: hidden; } }

.logo-bar {
    top: 60px; }

.index-link {
    display: inline-flex;

    .logo {
        margin-right: 17px;
        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5)); }

    .text {
        display: flex;
        flex-direction: column; }

    .ntpa {
        font-family: $font-family-OpenSans;
        color: white;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

    .pf {
        font-family: $font-family-OpenSans;
        color: white;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); } }

.index-link.tw {
    .logo {
        height: 68px; }

    .ntpa {
        font-size: calc-rem(21px);
        font-weight: 600;
        line-height: 1.19;
        letter-spacing: normal; }

    .pf {
        font-size: calc-rem(37px);
        font-weight: 600;
        line-height: 1.19;
        letter-spacing: normal; } }

.index-link.en {
    .ntpa {
        font-size: calc-rem(12px);
        letter-spacing: 1px; }

    .pf {
        font-size: calc-rem(21px);
        font-weight: bold;
        line-height: 1.57; } }

.lang-switch {
    float: right;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    > * {
        &:not(:last-child) {
            margin-right: 13px; } } }

@include lang-btn;

.index-nav {
    .normal-nav.not-fixed {
        position: absolute;
        top: auto;

        .index-link-color {
            opacity: 0;
            visibility: hidden; }

        .navigation.tw {
            > .item {
                margin: 0 calc((100% - 105px * 7 - 20px * 2) / (7 * 2 - 2));
                min-width: 105px; }

            > .item:first-child {
                margin-left: 20px; }

            > .item:last-child {
                margin-right: 20px; } }

        .navigation.en {
            > .item {
                margin: 0 55px; } } } }

.index {
    .first-row-up {
        margin-top: calc-percentage(64px, 1200px, %);

        .aspect-ratio-container {
            position: relative;
            padding-bottom: calc-percentage(351px, 668px, %);
            height: 0;

            .picture-container {
                height: 100%; }

            .img-container {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                img {
                    width: percentage(1 / 1.2);
                    margin-left: percentage((1 - 1 / 1.2) / 2); } } } }

    .first-row-down {
        margin-bottom: calc-percentage(55px, 1200px, %);

        .description-wrapper {
            position: relative;
            margin-top: calc-percentage(-226px, 568px, %);
            box-shadow: 0 4px 10px 0 rgba(148, 148, 148, 0.86);
            overflow: hidden;
            animation: shadow .2s ease 1s both;

            @keyframes shadow {
                0% {
                    box-shadow: none; }

                100% {
                    box-shadow: 0 4px 10px 0 rgba(148, 148, 148, 0.86); } } }

        .description {
            position: relative;
            padding: 20px 28px 22px;
            background-color: $rusty-red;
            opacity: 0;

            p {
                font-family: $font-family-OpenSans;
                font-size: calc-rem(16px);
                line-height: 1.69;
                letter-spacing: 1px;
                color: white;
                opacity: 0; } }

        .description.js-is-in-screen {
            animation: description-slideUp .5s ease .2s both;

            p {
                animation: description-slideUp .5s ease .7s both; }

            @keyframes description-slideUp {
                0% {
                    transform: translateY(40px);
                    opacity: 0; }

                100% {
                    transform: translateY(0);
                    opacity: .9; } }

            @keyframes description-p-slideUp {
                0% {
                    transform: translateY(40px);
                    opacity: 0; }

                100% {
                    transform: translateY(0);
                    opacity: 1; } } } }

    .second-row {
        margin-bottom: calc-percentage(55px, 1200px, %);

        &.info-container {
            .item {
                display: block;
                height: 0;
                padding-bottom: calc-percentage(231px, 368px, %); } } }

    .info-header {
        margin-bottom: calc-percentage(12px, 1200px, %);
        font-family: $font-family-SpectralSC;
        font-size: calc-rem(28px);
        font-weight: bold;
        line-height: 1.18;
        text-align: left;
        color: $dried-blood; }

    .index-top-btn-container {
        margin: 55px 0 44px;
        text-align: center;

        @include index-top-btn; }

    .tw-second-row, .tw-third-row {
        h2 {
            position: relative;
            margin-bottom: 16px;
            font-size: calc-rem(28px);
            font-weight: 600;
            line-height: 1.18;
            text-align: left;
            color: $dried-blood;

            span {
                position: absolute;
                top: 0;
                right: 0;
                font-family: $font-family-SpectralSC;
                font-weight: bold;
                text-align: right;
                color: $beige; } } }

    .tw-second-row {
        .info-container {
            display: flex;
            flex-wrap: wrap;
            margin: -4px -4px 50px;

            .item {
                flex: 0 0;
                flex-basis: calc((100% - 16px) / 2);
                // ie bug, temp solution 4 --> 3.9
                margin: 3.9px;

                .aspect-ratio-container {
                    height: 0;
                    padding-bottom: 100%; } } } }


    .third-row {
        .info-container {
            .item {
                display: block;
                height: 0;
                padding-bottom: calc-percentage(271px, 1168px, %); } } }


    .info-container {
        .item {
            position: relative;
            overflow: hidden;

            .img-container {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateZ(0);
                transition: all .24s ease-in-out; }

            .text-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                p {
                    font-family: $font-family-OpenSans;
                    font-size: calc-rem(21px);
                    font-weight: bold;
                    line-height: 1.19;
                    text-align: center;
                    color: white;
                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); } }

            .text-container.facebook-link {
                p {
                    margin-bottom: calc-percentage(30px, 1168px, %);
                    font-size: calc-rem(28px);
                    letter-spacing: 2px; }

                .fb-icon {
                    width: 70px;
                    height: 70px;
                    background-image: url('../../assets/img/icon/btn-fb.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    transition: all .2s;
                    @include preload-image('../../assets/img/icon/btn-fb-hover.svg'); } }

            .text-container.facebook-link.tw {
                p {
                    font-family: $font-family-primary; } }

            .text-container.facebook-link.en {
                p {
                    font-family: $font-family-SpectralSC; } } }

        .item:hover {
            .img-container {
                transform: translateZ(0) scale(1.08); }

            .text-container.facebook-link {
                .fb-icon {
                    background-image: url('../../assets/img/icon/btn-fb-hover.svg'); } } } }

    .news-container {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 50px;
        padding: calc-percentage(15px, 668px, %) 0 calc-percentage(70px, 668px, %);
        border-top: solid 5px $rusty-red;
        background-color: white;

        > .item {
            display: flex;
            align-items: center;
            padding: 10px 33px;
            transition: all .2s;

            @include date-container;
            .date-container {
                flex: 0 0 55px;
                margin-right: 25px;
                height: 55px; }

            .content {
                max-width: calc(100% - 80px);

                .header {
                    display: flex;

                    h3 {
                        font-family: $font-family-primary;
                        font-size: calc-rem(16px);
                        line-height: 1.75;
                        letter-spacing: 1px;
                        text-align: left;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: $charcoal-grey; } } } }

        > .item:hover {
            background-color: rgba(230, 203, 197, 0.4); }

        > .item.new {
            .content {
                .header:after {
                    content: 'New!';
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-left: 6px;
                    padding: 2px 6px;
                    box-sizing: border-box;
                    border-radius: 2px;
                    background-color: $rose;
                    font-family: $font-family-OpenSans;
                    font-size: calc-rem(14px);
                    line-height: 1.43;
                    letter-spacing: 1px;
                    text-align: center;
                    color: white; } } }

        .more {
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-bottom: calc-percentage(30px, 668px, %);
            transform: translateX(-50%);
            font-family: $font-family-OpenSans;
            font-size: calc-rem(16px);
            line-height: 1.5;
            letter-spacing: 1px;
            text-align: center;
            color: $rusty-red;

            &:after {
                content: '';
                display: block;
                position: absolute;
                margin-top: 5px;
                width: 100%;
                height: 2px;
                transform: scaleX(0);
                background-color: $rusty-red;
                transition: all .2s; } }

        .more:hover {
            &:after {
                transform: scaleX(1); } } }

    .speech-container {
        display: flex;
        flex-wrap: wrap;
        margin: -15px -15px 50px;

        > .item {
            flex: 0 0;
            flex-basis: calc((100% - 15px * 6) / 3);
            position: relative;
            margin: 15px;
            background-color: white;

            .img-wrapper {
                position: relative;
                padding-bottom: calc-percentage(267px, 368px, %);
                height: 0;
                overflow: hidden;

                .img-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transition: all .24s ease-in-out;

                    .js-parallax {
                        object-fit: contain; } } }

            .content-container {
                position: relative;
                transform: translateZ(0);
                margin-top: calc-percentage(-36px, 368px, %);
                padding: 0 calc-percentage(35px, 368px, %) calc(#{calc-percentage(25px, 368px, %)} + #{calc-rem(16px) * 1.5});
                z-index: 1;

                @include date-container;
                .date-container.red {
                    width: 66px;
                    height: 72px;
                    background-color: $rusty-red;
                    box-shadow: 0 2px 3px 0 rgba(75, 9, 3, 0.49);

                    .day {
                        font-size: calc-rem(26px); } }

                .speaker {
                    margin-top: calc-percentage(10px, 298px, %);
                    font-size: calc-rem(14px);
                    letter-spacing: 1px;
                    color: $rusty-red; }

                .title {
                    margin-top: calc-percentage(8px, 298px, %);
                    font-size: calc-rem(21px);
                    font-weight: 600;
                    letter-spacing: 1px;
                    text-align: left;
                    color: $dried-blood; }

                .time {
                    margin-top: calc-percentage(9px, 298px, %);

                    p {
                        font-family: $font-family-OpenSans;
                        font-size: calc-rem(14px);
                        letter-spacing: 1px;
                        color: $charcoal-grey; }

                    .table-row-style {
                        .decoration {
                            padding-right: 8px;

                            &:before {
                                content: '';
                                display: block;
                                position: relative;
                                top: calc(1em * (#{$normal-line-height} - 1) / 2);
                                width: 15px;
                                height: 15px;
                                background-image: url('../../assets/img/icon/icon-time.svg');
                                background-size: contain;
                                background-position: top left;
                                background-repeat: no-repeat; } } } }

                .location {
                    margin-top: calc-percentage(9px, 298px, %);
                    margin-bottom: calc-percentage(14px, 298px, %);

                    p {
                        font-size: calc-rem(14px);
                        letter-spacing: 1px;
                        color: $charcoal-grey; }

                    .table-row-style {
                        .decoration {
                            padding-right: 8px;

                            &:before {
                                content: '';
                                display: block;
                                position: relative;
                                top: calc(1em * (#{$normal-line-height} - 1) / 2);
                                width: 15px;
                                height: 15px;
                                background-image: url('../../assets/img/icon/icon-location.svg');
                                background-size: contain;
                                background-position: top left;
                                background-repeat: no-repeat; } } } } }

            .more {
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-bottom: calc-percentage(25px, 368px, %);
                transform: translateX(-50%);
                font-family: $font-family-OpenSans;
                font-size: calc-rem(16px);
                line-height: 1.5;
                letter-spacing: 1px;
                text-align: center;
                color: $rusty-red;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    margin-top: 5px;
                    width: 100%;
                    height: 2px;
                    transform: translateZ(0) scaleX(0);
                    background-color: $rusty-red;
                    transition: all .2s; } } }

        > .item:hover {
            .img-wrapper {
                .img-container {
                    transform: scale(1.08); } }

            .more {
                &:after {
                    transform: translateZ(0) scaleX(1); } } } } }

@media all and (max-width: $tablet) {
    .hero-home {
        height: calc-percentage(355px, 768px, vw);
        max-height: 355px;

        .img-container {
            height: calc-percentage(355px, 768px, vw);
            max-height: 355px; } }

    .logo-bar {
        top: 38px; }

    .index-link {
        display: none; }

    .index-nav {
        padding-bottom: 0;

        .normal-nav {
            background-color: rgba(255, 255, 255, 0.8);
            transition: all .5s;

            &.show {
                background-color: white; } }

        .normal-nav.not-fixed {
            position: fixed;
            top: 0;

            .index-link-color {
                opacity: 1;
                visibility: visible; } } }

    .lang-switch {
        display: none; }

    .index {
        .first-row-up {
            margin-top: calc-percentage(21px, 728px, %);

            &.grid > [class*='col']:first-child {
                flex-basis: calc-percentage(530px, 728px, %);
                max-width: calc-percentage(530px, 728px, %); }

            .aspect-ratio-container {
                padding-bottom: calc-percentage(327px, 530px, %); } }

        .first-row-down {
            margin-bottom: calc-percentage(19px, 728px, %);

            &.grid > [class*='col']:first-child {
                flex-basis: calc-percentage(520px, 728px, %);
                max-width: calc-percentage(520px, 728px, %);
                margin-left: calc-percentage(208px, 728px, %); }

            .description-wrapper {
                margin-top: calc-percentage(-114px, 520px, %);
                box-shadow: none;
                animation: none; }

            .description {
                padding: 18px 18px 13px 23px; } }

        .second-row {
            margin-bottom: calc-percentage(35px, 728px, %);

            &.grid > [class*='col']:nth-child(2) {
                padding-right: 7.5px; }

            &.grid > [class*='col']:nth-child(3) {
                padding-left: 7.5px; }

            &.info-container {
                .item {
                    padding-bottom: calc-percentage(130px, 233px, %); } } }

        .tw-second-row {
            &.grid > [class*='col'] {
                flex-basis: 100%;
                max-width: 100%; }

            .news-container {
                margin-bottom: 40px;

                > .item:hover {
                    background-color: transparent; }

                > .item:hover:active {
                    background-color: rgba(230, 203, 197, 0.4); }

                .more:hover {
                    &:after {
                        transform: scaleX(0); } }

                .more:hover:active {
                    &:after {
                        transform: scaleX(1); } } }

            .info-container {
                margin: -7px -7px 40px;

                > .item:nth-child(1) {
                    flex-basis: calc(100% - 7px * 2);
                    margin: 7px;

                    .aspect-ratio-container {
                        padding-bottom: calc-percentage(186px, 727px, %); } }

                > .item:nth-child(n + 2) {
                    flex-basis: calc((100% - 7px * 6) / 3);
                    margin: 7px;

                    .aspect-ratio-container {
                        padding-bottom: calc-percentage(130px, 233px, %); } } } }

        .tw-third-row {
            .speech-container {
                margin: -5px -5px 40px;

                > .item {
                    flex-basis: calc((100% - 5px * 4) / 2);
                    margin: 5px; }

                > .item:nth-child(n + 3) {
                    display: none; }

                > .item:hover {
                    .img-wrapper {
                        .img-container {
                            transform: scale(1); } }

                    .more {
                        &:after {
                            transform: translateZ(0) scaleX(0); } } }


                > .item:hover:active {
                    .img-wrapper {
                        .img-container {
                            transform: scale(1.08); } }

                    .more {
                        &:after {
                            transform: translateZ(0) scaleX(1); } } } } }

        .third-row {
            .info-container {
                .item {
                    padding-bottom: calc-percentage(205px, 728px, %); } } }

        .index-top-btn-container {
            margin: 30px 0; }

        .info-container {
            .item {
                .text-container.facebook-link {
                    p {
                        margin-bottom: calc-percentage(21px, 728px, %);
                        font-size: calc-rem(21px);
                        letter-spacing: 4px;
                        text-indent: 4px; } } }

            .item:hover {
                .img-container {
                    transform: translateZ(0); }

                .text-container.facebook-link {
                    .fb-icon {
                        background-image: url('../../assets/img/icon/btn-fb.svg'); } } }

            .item:hover:active {
                .img-container {
                    transform: translateZ(0) scale(1.08); }

                .text-container.facebook-link {
                    .fb-icon {
                        background-image: url('../../assets/img/icon/btn-fb-hover.svg'); } } } } } }

@media all and (max-width: $mobile) {
    .hero-home {
        height: calc-percentage(256px, 375px, vw);
        max-height: 256px;

        .img-container {
            height: calc-percentage(256px, 375px, vw);
            max-height: 256px; } }

    .logo-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        height: 100%; }

    //.index-link
    //    display: flex
    //    flex-direction: column
    //    align-items: center
    //    height: 100%
    //
    //    .logo
    //        margin-right: 0
    //        margin-bottom: 18px
    //
    //    .text
    //        align-items: center

    .index {
        .first-row-up {
            margin-top: calc-percentage(25px, 343px, %);

            &.grid > [class*='col']:first-child {
                flex-basis: 100%;
                max-width: 100%; }

            .aspect-ratio-container {
                padding-bottom: calc-percentage(175px, 343px, %); } }

        .first-row-down {
            margin-bottom: calc-percentage(39px, 343px, %);

            &.grid > [class*='col']:first-child {
                flex-basis: 100%;
                max-width: 100%;
                margin-left: 0; }

            .description-wrapper {
                margin-top: 0; }

            .description {
                padding: 13px 15px 12px;

                p {
                    font-size: calc-rem(14px);
                    line-height: 1.71; } } }

        .second-row {
            margin-bottom: calc-percentage(30px, 343px, %);

            &.grid > [class*='col']:nth-child(2) {
                padding-right: 3.5px; }

            &.grid > [class*='col']:nth-child(3) {
                padding-left: 3.5px; }

            &.grid > [class*='col']:first-child {
                flex-basis: 100%;
                max-width: 100%;
                margin-bottom: 7px; }

            &.info-container {
                > *:nth-child(1) .item {
                    padding-bottom: calc-percentage(102px, 341px, %); }

                > *:nth-child(2) .item, > *:nth-child(3) .item {
                    padding-bottom: calc-percentage(102px, 168px, %); } } }

        .tw-second-row, .tw-third-row {
            h2 {
                margin-bottom: 14px;
                font-size: calc-rem(21px); } }

        .tw-second-row {
            .news-container {
                margin-bottom: 33px;
                padding: calc-percentage(10px, 343px, %) 0 calc-percentage(60px, 343px, %);

                > .item {
                    padding: 10px 13px;

                    .date-container {
                        margin-right: 15px; }

                    .content {
                        max-width: calc(100% - 70px);

                        .header {
                            h3 {
                                line-height: 1.25; } } } }

                > .item.new {
                    .content {
                        .header:after {
                            font-size: calc-rem(12px); } } } }

            .info-container {
                margin: -3.5px -3.5px 33px;

                > .item:nth-child(n + 1) {
                    flex-basis: calc((100% - 3.5px * 4) / 2);
                    margin: 3.5px;

                    .aspect-ratio-container {
                        padding-bottom: calc-percentage(102px, 168px, %); } } } }

        .tw-third-row {
            .speech-container {
                margin-bottom: 33px;

                > .item {
                    flex-basis: calc(100% - 5px * 2);

                    .content-container {
                        padding: 0 calc-percentage(27px, 343px, %) calc-percentage(21px, 343px, %);

                        .location {
                            margin-bottom: 0; } }

                    .more {
                        display: none; } } } }

        .third-row {
            .info-container {
                .item {
                    padding-bottom: calc-percentage(200px, 343px, %); } } }

        .info-header {
            margin-bottom: calc-percentage(15px, 343px, %);
            font-size: calc-rem(21px);
            font-weight: 600;
            line-height: 1.19; }

        .index-top-btn-container {
            margin: 25px 0;

            .index-top-btn {
                width: 40px;
                height: 40px;
                background-size: 40px 40px; } } } }
