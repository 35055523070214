.hero {
    display: flex;
    align-items: center;
    position: relative;
    max-height: 300px;
    overflow: hidden;

    img {
        width: 100vw; }

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;

        h1 {
            margin-bottom: 50px;
            font-family: $font-family-OpenSans;
            font-size: calc-rem(30px);
            letter-spacing: 5px;
            text-align: center;
            color: white; } } }

@media all and (max-width: $tablet) {
    .hero {
        max-height: 266px;

        .header {
            h1 {
                margin-bottom: 0; } } } }

@media all and (max-width: $mobile) {
    .hero {
        max-height: 188px;

        .header {
            h1 {
                font-size: calc-rem(21px);
                letter-spacing: 3px; } } } }
