@mixin lang-btn {
    .lang-btn {
        width: 50px;
        height: 50px;
        line-height: 1.5;
        border-radius: 50%;
        font-size: calc-rem(16px);
        letter-spacing: 1px;
        background-color: white;
        color: $rusty-red;
        transition: all .2s;

        &.tw {
            font-weight: 600; }

        &.en {
            font-weight: bold; }

        &.active {
            cursor: default;
            background-color: $rusty-red;
            color: white; }

        &:not(.active) {
            &:hover {
                background-color: #f4f4f4; } } } }

@mixin hamburger-btn {
    .hamburger-btn {
        width: 32px;
        height: 32px;
        background-image: url(../../assets/img/icon/icon-menu.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat; } }

@mixin nav-close-btn {
    .nav-close-btn {
        width: 71px;
        height: 71px;
        background-color: white;
        background-image: url(../../assets/img/icon/icon-close.svg);
        background-size: 34px 34px;
        background-position: center;
        background-repeat: no-repeat; } }

@mixin expand-switch {
    .expand-switch {
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
        background-position: center;
        background-repeat: no-repeat; }

    .expand-switch.plus {
        background-image: url(../../assets/img/icon/icon-menu-plus.svg);
        @include preload-image('../../assets/img/icon/icon-menu-minus.svg');

        &.on {
            background-image: url(../../assets/img/icon/icon-menu-minus.svg); } }

    .expand-switch.arrow {
        background-image: url(../../assets/img/icon/icon-arrowdown-white.svg);
        @include preload-image('../../assets/img/icon/icon-arrowup-white.svg');

        &.on {
            background-image: url(../../assets/img/icon/icon-arrowup-white.svg); } } }

@mixin card-expand-switch {
    .card-expand-switch {
        width: 20px;
        height: 20px;
        background-image: url(../../assets/img/icon/icon-plus.svg);
        background-size: 20px 20px;
        background-position: center;
        background-repeat: no-repeat;
        @include preload-image('../../assets/img/icon/icon-minus.svg');
        transition: all .2s;

        &.on {
            background-image: url(../../assets/img/icon/icon-minus.svg); } } }

@mixin show-btn {
    .show-btn {
        padding-left: 18px + 11px;
        background-size: 18px 18px;
        background-position: left center;
        background-repeat: no-repeat;
        font-family: $font-family-OpenSans;
        font-size: calc-rem(14px);
        font-weight: bold;
        line-height: 1.71;
        letter-spacing: 1px;
        color: $rose;
        transition: all .2s;
        cursor: pointer; }

    .show-btn.more {
        background-image: url(../../assets/img/icon/btn-showmore.svg);

        &:before {
            content: 'Show more'; } }

    .show-btn.less {
        background-image: url(../../assets/img/icon/btn-showless.svg);

        &:before {
            content: 'Show less'; } } }

@mixin index-top-btn {
    .index-top-btn {
        width: 55px;
        height: 55px;
        background-image: url(../../assets/img/icon/btn-top.svg);
        background-size: 55px 55px;
        background-position: center;
        background-repeat: no-repeat; } }

.top-btn {
    position: fixed;
    width: 53px;
    height: 53px;
    background-image: url(../../assets/img/icon/btn-top-circle.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
    opacity: 0;
    visibility: hidden;
    z-index: 1010;
    transition: opacity .2s;
    @include preload-image('../../assets/img/icon/btn-top-circle-hover.svg');

    &.visible {
        opacity: 1;
        visibility: visible; }

    &:hover {
        background-image: url(../../assets/img/icon/btn-top-circle-hover.svg); } }

@media all and (max-width: $tablet) {
    .top-btn {
        display: none; } }

@mixin link-rectangle-btn {
    .link-rectangle-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 22px 11px;
        width: 254px;
        box-sizing: border-box;
        border-radius: 2px;
        background-color: #f4f0ea;

        .main {
            font-family: $font-family-OpenSans;
            font-size: calc-rem(14px);
            letter-spacing: 1px;
            text-align: center;
            color: $charcoal-grey;
            transition: all .2s; }

        .sub {
            margin-top: 4px;
            font-family: $font-family-OpenSans;
            font-size: calc-rem(12px);
            letter-spacing: 1px;
            text-align: center;
            color: #a3a3a3; } }

    .link-rectangle-btn:hover {
        box-shadow: 0 3px 4px 0 #bcb8b3;

        .main {
            font-weight: 600;
            color: $rusty-red; } }

    .link-rectangle-btn[download] {
        .main {
            &:before {
                content: url('../../assets/img/icon/icon-download.svg');
                display: inline-block;
                position: relative;
                top: 3px;
                margin-right: 0;
                width: 0;
                height: 13px;
                opacity: 0;
                transition: all .2s; } } }

    .link-rectangle-btn[download]:hover {
        .main {
            &:before {
                margin-right: 9px;
                width: 13px;
                opacity: 1; } } }

    @media all and (max-width: $tablet) {
        .link-rectangle-btn:hover {
            box-shadow: none;

            .main {
                font-weight: normal;
                color: $charcoal-grey; } }

        .link-rectangle-btn:hover:active {
            box-shadow: 0 3px 4px 0 #bcb8b3;

            .main {
                font-weight: 600;
                color: $rusty-red; } }

        .link-rectangle-btn[download]:hover {
            .main {
                &:before {
                    margin-right: 0;
                    width: 0;
                    opacity: 0; } } }

        .link-rectangle-btn[download]:hover:active {
            .main {
                &:before {
                    margin-right: 9px;
                    width: 13px;
                    opacity: 1; } } } } }

@mixin post-prev-next-btn {
    .post-prev-btn, .post-next-btn {
        display: block;
        width: 63px;
        height: 63px;
        background-color: white;
        background-size: 30px 30px;
        background-position: center;
        background-repeat: no-repeat;
        transition: all .2s;

        &:hover {
            background-color: $dried-blood; } }

    .post-prev-btn {
        background-image: url(../../assets/img/icon/news-arrow-left-dark.svg);
        @include preload-image('../../assets/img/icon/news-arrow-left-white.svg');

        &:hover {
            background-image: url(../../assets/img/icon/news-arrow-left-white.svg); } }

    .post-next-btn {
        background-image: url(../../assets/img/icon/news-arrow-right-dark.svg);
        @include preload-image('../../assets/img/icon/news-arrow-right-white.svg');

        &:hover {
            background-image: url(../../assets/img/icon/news-arrow-right-white.svg); } }

    @media all and (max-width: $mobile) {
        .post-prev-btn, .post-next-btn {
            width: 50px;
            height: 50px; } } }

@mixin primary-btn {
    .primary-btn {
        display: block;
        padding: 8px 18px;
        min-width: 100px;
        background-color: $dried-blood;
        font-size: calc-rem(14px);
        letter-spacing: 1px;
        line-height: $normal-line-height;
        text-align: center;
        color: white; } }

@mixin close-btn {
    .close-btn {
        width: 15px;
        height: 15px;
        background-image: url('../../assets/img/icon/icon-close-grey.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat; } }

@mixin arrow-btn {
    .arrow-btn {
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
        background-position: center;
        background-repeat: no-repeat;
        transition: all .2s; }

    .arrow-btn.left {
        background-image: url(../../assets/img/icon/icon-arrowleft-white.svg); }

    .arrow-btn.right {
        background-image: url(../../assets/img/icon/icon-arrowright-white.svg); } }
