@charset "UTF-8";
.top-btn {
  position: fixed;
  width: 53px;
  height: 53px;
  background-image: url(../../assets/img/icon/btn-top-circle.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  outline: none;
  opacity: 0;
  visibility: hidden;
  z-index: 1010;
  transition: opacity .2s;
}

.top-btn:after {
  content: "";
  display: none;
  background-image: url("../../assets/img/icon/btn-top-circle-hover.svg");
}

.top-btn.visible {
  opacity: 1;
  visibility: visible;
}

.top-btn:hover {
  background-image: url(../../assets/img/icon/btn-top-circle-hover.svg);
}

@media all and (max-width: 768px) {
  .top-btn {
    display: none;
  }
}

.index-link-color {
  display: inline-flex;
  align-items: center;
  transition: .5s ease-out;
}

.index-link-color .logo {
  margin-right: 14px;
  width: 72px;
  height: 48px;
  filter: none;
}

.index-link-color .text {
  display: flex;
  flex-direction: column;
}

.index-link-color .ntpa, .index-link-color .pf {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  color: #273951;
  text-shadow: none;
}

.index-link-color.en .ntpa {
  font-size: 0.75rem;
  letter-spacing: 1px;
}

.index-link-color.en .pf {
  font-size: 1.3125rem;
  font-weight: bold;
}

.index-link-color.tw .ntpa {
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.index-link-color.tw .pf {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.18;
}

.normal-nav-wrapper {
  padding-bottom: 96px;
}

.normal-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
}

.normal-nav .index-link-color {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.normal-nav .navigation {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.normal-nav .navigation > .item {
  position: relative;
  margin: 0 20px;
  padding: 37px 0;
  min-width: 68px;
  transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  cursor: default;
}

.normal-nav .navigation > .item p {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 1rem;
  letter-spacing: 1px;
  text-align: center;
  color: #3c3d3e;
}

.normal-nav .navigation > .item .sub-nav {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  padding: 16px 0;
  min-width: calc(105px * 1.18);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1), z-index 0s;
}

.normal-nav .navigation > .item .sub-nav:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transform: scale(0.9);
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  opacity: 0;
  z-index: -1;
  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1), z-index 0s;
  transform-origin: top;
}

.normal-nav .navigation > .item .sub-nav li {
  padding: 10px 20px;
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.875rem;
  line-height: 1.375;
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  color: #3c3d3e;
  transition: all .2s;
}

.normal-nav .navigation > .item .sub-nav li a {
  display: block;
  font: inherit;
  color: inherit;
}

.normal-nav .navigation > .item .sub-nav li:hover {
  color: #a31d11;
}

.normal-nav .navigation > .item:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: scaleX(0);
  height: 4px;
  background-color: #d22a1c;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.normal-nav .navigation > .item.active:after {
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%) scaleX(1);
  width: 30px;
  opacity: 1;
  visibility: visible;
}

.normal-nav .navigation > .item:hover .sub-nav {
  opacity: 1;
  visibility: visible;
}

.normal-nav .navigation > .item:hover .sub-nav:after {
  transform: scale(1);
  opacity: 1;
}

.normal-nav .navigation > .item:hover:after {
  bottom: 0;
  left: 0;
  transform: scaleX(1.18);
  width: 100%;
  opacity: 1;
  visibility: visible;
}

.nav-wrapper {
  display: flex;
  align-items: center;
}

.hamburger-btn {
  width: 32px;
  height: 32px;
  background-image: url(../../assets/img/icon/icon-menu.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.hamburger-btn {
  display: none;
}

.mobile-nav {
  display: none;
}

.side-nav .navigation > .item {
  position: relative;
  max-height: calc(0.875rem * 1.375 + 23px * 2);
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.side-nav .navigation > .item:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #d22a1c;
}

.side-nav .navigation > .item > p, .side-nav .navigation > .item > a p {
  display: flex;
  align-items: center;
  padding: 0 25px;
  width: 100%;
  height: calc(0.875rem * 1.375 + 23px * 2);
  box-sizing: border-box;
  background-color: white;
  border: solid 1px #f7f7f7;
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: #333333;
  cursor: pointer;
  transition: all .2s;
}

.side-nav .navigation > .item .expand-arrow {
  position: absolute;
  top: calc(0.875rem * 1.375 / 2 + 23px - 15px / 2);
  right: 25px;
  width: 15px;
  height: 15px;
  background-image: url(../../assets/img/icon/icon-arrowdown.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .2s;
  cursor: pointer;
}

.side-nav .navigation > .item .expand-arrow.on {
  transform: rotateX(-180deg);
}

.side-nav .navigation > .item .sub-nav li {
  position: relative;
  background-color: #e2e2e2;
  border: solid 1px #f8f8f8;
}

.side-nav .navigation > .item .sub-nav li:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 7px;
  height: 100%;
  transform: scaleY(0);
  background-color: #d22a1c;
  transition: all .2s;
}

.side-nav .navigation > .item .sub-nav li > a p {
  display: block;
  padding: 15px 15px 15px 45px;
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: rgba(51, 51, 51, 0.6);
}

.side-nav .navigation > .item .sub-nav li > a[target='_blank'] p:after {
  content: url("../../assets/img/icon/icon-link.svg");
  display: inline-block;
  position: relative;
  top: calc(0.875rem * 1.375 - 16px);
  left: 6px;
  width: 16px;
  height: 16px;
}

.side-nav .navigation > .item .sub-nav li:hover:before {
  transform: scaleY(1);
}

.side-nav .navigation > .item.active:before {
  width: 7px;
}

.side-nav .navigation > .item.active > a p {
  font-weight: bold;
}

.side-nav .navigation > .item.expand {
  max-height: 500px;
  transition: all 0.2s cubic-bezier(0.71, 0.01, 1, 0.32);
}

.side-nav .navigation > .item.expand:before {
  width: 0;
}

.side-nav .navigation > .item.expand > p {
  font-weight: 600;
}

.side-nav .navigation > .item.expand .sub-nav li.active:before {
  transform: scaleY(1);
}

.side-nav .navigation > .item.expand .sub-nav li.active > a p {
  color: #333333;
}

@media all and (max-width: 768px) {
  .nav-wrapper {
    height: 91px;
  }
  .index-link-color {
    margin-left: 4px;
  }
  .index-link-color .logo {
    margin-right: 11px;
    width: 72px;
    height: 48px;
  }
  .index-link-color.tw .pf {
    font-size: 1.25rem;
    line-height: 1.375;
  }
  .normal-nav-wrapper {
    padding-bottom: 91px;
  }
  .normal-nav .navigation {
    display: none;
  }
  .hamburger-btn {
    display: block;
    margin-left: auto;
    margin-right: 10px;
  }
  .mobile-nav {
    display: block;
  }
  .mobile-nav .container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    z-index: 1001;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), z-index 0s;
    transform-origin: right;
  }
  .mobile-nav .container .drawer {
    display: flex;
    justify-content: flex-end;
    margin-right: -552px;
    height: 100%;
    transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .mobile-nav .container .drawer .nav-close-btn {
    width: 71px;
    height: 71px;
    background-color: white;
    background-image: url(../../assets/img/icon/icon-close.svg);
    background-size: 34px 34px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .mobile-nav .container .drawer .panel {
    position: relative;
    width: 481px;
    height: 100%;
    background-color: #a31d11;
  }
  .mobile-nav .container .drawer .panel .lang-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 46px;
    padding: 0 62px;
    width: 100%;
  }
  .mobile-nav .container .drawer .panel .lang-container .more-on-fb {
    display: flex;
    align-items: center;
  }
  .mobile-nav .container .drawer .panel .lang-container .more-on-fb p {
    font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 1px;
    text-align: left;
    color: white;
  }
  .mobile-nav .container .drawer .panel .lang-container .more-on-fb .fb-icon {
    margin-left: 8px;
    width: 30px;
    height: 30px;
    background-image: url(../../assets/img/icon/btn-fb.svg);
    background-size: 30px 30px;
    background-position: center;
    background-repeat: no-repeat;
    transition: all .2s;
  }
  .mobile-nav .container .drawer .panel .lang-container .more-on-fb .fb-icon:after {
    content: "";
    display: none;
    background-image: url("../../assets/img/icon/btn-fb-hover.svg");
  }
  .mobile-nav .container .drawer .panel .lang-container .more-on-fb:hover:active .fb-icon {
    background-image: url(../../assets/img/icon/btn-fb-hover.svg);
  }
  .mobile-nav .container .drawer .panel .lang-container .lang {
    display: flex;
  }
  .mobile-nav .container .drawer .panel .lang-container .lang li {
    font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
    font-size: 0.875rem;
    line-height: 1.43;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
  }
  .mobile-nav .container .drawer .panel .lang-container .lang li a {
    font: inherit;
    font-weight: normal;
    color: inherit;
  }
  .mobile-nav .container .drawer .panel .lang-container .lang li:nth-child(1):after {
    content: '/';
    display: inline-block;
    margin: 0 10px 0 8px;
  }
  .mobile-nav .expand-switch {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .mobile-nav .expand-switch.plus {
    background-image: url(../../assets/img/icon/icon-menu-plus.svg);
  }
  .mobile-nav .expand-switch.plus:after {
    content: "";
    display: none;
    background-image: url("../../assets/img/icon/icon-menu-minus.svg");
  }
  .mobile-nav .expand-switch.plus.on {
    background-image: url(../../assets/img/icon/icon-menu-minus.svg);
  }
  .mobile-nav .expand-switch.arrow {
    background-image: url(../../assets/img/icon/icon-arrowdown-white.svg);
  }
  .mobile-nav .expand-switch.arrow:after {
    content: "";
    display: none;
    background-image: url("../../assets/img/icon/icon-arrowup-white.svg");
  }
  .mobile-nav .expand-switch.arrow.on {
    background-image: url(../../assets/img/icon/icon-arrowup-white.svg);
  }
  .mobile-nav .navigation.tw .arrow-btn {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    transition: all .2s;
  }
  .mobile-nav .navigation.tw .arrow-btn.left {
    background-image: url(../../assets/img/icon/icon-arrowleft-white.svg);
  }
  .mobile-nav .navigation.tw .arrow-btn.right {
    background-image: url(../../assets/img/icon/icon-arrowright-white.svg);
  }
  .mobile-nav .navigation.tw .header {
    position: relative;
    background-color: rgba(75, 9, 3, 0.2);
  }
  .mobile-nav .navigation.tw .header .arrow-btn {
    position: absolute;
    top: calc(1rem * 1.5 / 2 + 25px - 20px / 2);
    left: 37px;
    margin: -10px;
    padding: 20px;
  }
  .mobile-nav .navigation.tw .header p {
    padding: 28px 0;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    color: white;
  }
  .mobile-nav .navigation.tw > .item {
    position: relative;
    max-height: calc(1rem * 1.88 + 25px * 2);
    overflow: hidden;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .mobile-nav .navigation.tw > .item p, .mobile-nav .navigation.tw > .item a {
    display: block;
    padding: 25px 62px;
    font-size: 1rem;
    line-height: 1.88;
    color: white;
    transition: all .2s;
  }
  .mobile-nav .navigation.tw > .item .arrow-btn, .mobile-nav .navigation.tw > .item .expand-switch {
    position: absolute;
    top: calc(1rem * 1.88 / 2 + 25px - 20px / 2);
    right: 62px;
  }
  .mobile-nav .navigation.tw > .item .sub-nav li a {
    display: block;
    padding: 10px 10px 10px 76px;
    font-size: 1rem;
    line-height: 1.88;
    color: white;
  }
  .mobile-nav .navigation.tw > .item .sub-nav li a[target='_blank']:after {
    content: url("../../assets/img/icon/icon-link-white.svg");
    display: inline-block;
    position: relative;
    top: 3px;
    margin-left: 24px;
    width: 16px;
    height: 16px;
  }
  .mobile-nav .navigation.tw > .item.active {
    padding-bottom: 15px;
    max-height: 500px;
    transition: all 0.2s cubic-bezier(0.71, 0.01, 1, 0.32);
  }
  .mobile-nav .navigation.tw > .item.active p, .mobile-nav .navigation.tw > .item.active a {
    padding-bottom: 15px;
  }
  .mobile-nav .navigation.tw > .item:not(:first-child) {
    border-top: solid 1px rgba(255, 255, 255, 0.5);
  }
  .mobile-nav .navigation.tw .header + .item {
    border-top: none;
  }
  .mobile-nav .navigation.tw.slide-nav {
    position: absolute;
    top: 0;
    right: -100%;
    bottom: 0;
    left: 100%;
    background-color: #a31d11;
    transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .mobile-nav .navigation.tw.slide-nav.active {
    right: 0;
    left: 0;
  }
  .mobile-nav .navigation.en {
    margin: 75px 62px 0;
  }
  .mobile-nav .navigation.en > .item {
    position: relative;
    max-height: calc(1rem * 1.69 + 25px * 2);
    overflow: hidden;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .mobile-nav .navigation.en > .item p {
    padding: 25px 12px;
    font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.69;
    letter-spacing: 1px;
    color: white;
  }
  .mobile-nav .navigation.en > .item .expand-switch {
    position: absolute;
    top: calc(1rem * 1.69 / 2 + 25px - 20px / 2);
    right: 12px;
  }
  .mobile-nav .navigation.en > .item .sub-nav li {
    margin-bottom: 25px;
    padding-left: 30px;
    font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
    font-size: 1rem;
    line-height: 1.69;
    letter-spacing: 1px;
    color: white;
  }
  .mobile-nav .navigation.en > .item .sub-nav li a {
    display: block;
    font: inherit;
    color: inherit;
  }
  .mobile-nav .navigation.en > .item.active {
    max-height: 500px;
    transition: all 0.2s cubic-bezier(0.71, 0.01, 1, 0.32);
  }
  .mobile-nav .navigation.en > .item:not(:first-child) {
    border-top: solid 1px white;
  }
  .mobile-nav.active .container {
    opacity: 1;
    visibility: visible;
  }
  .mobile-nav.active .container .drawer {
    margin-right: 0;
  }
  .side-nav {
    display: none;
  }
}

@media all and (max-width: 480px) {
  .nav-wrapper {
    height: 55px;
  }
  .index-link-color .logo {
    margin-right: 8px;
    width: 45px;
    height: 30px;
  }
  .index-link-color.en .ntpa {
    font-size: 0.5rem;
    letter-spacing: .5px;
  }
  .index-link-color.en .pf {
    font-size: 0.875rem;
  }
  .index-link-color.tw .ntpa {
    font-size: 0.625rem;
  }
  .index-link-color.tw .pf {
    font-size: 1rem;
  }
  .normal-nav-wrapper {
    padding-bottom: 55px;
  }
  .hamburger-btn {
    margin-right: 0;
  }
  .mobile-nav .container .drawer {
    margin-right: -326px;
  }
  .mobile-nav .container .drawer .panel {
    width: 255px;
  }
  .mobile-nav .container .drawer .panel .lang-container {
    bottom: 31px;
    padding: 0 21px 0 32px;
  }
  .mobile-nav .container .drawer .panel .lang-container .more-on-fb p {
    display: none;
  }
  .mobile-nav .container .drawer .panel .lang-container .more-on-fb .fb-icon {
    margin-left: 0;
  }
  .mobile-nav .container .drawer .panel.mobile-tw-width {
    width: 289px;
  }
  .mobile-nav .navigation.tw .header .arrow-btn {
    top: calc(0.875rem * 1.375 / 2 + 20px - 20px / 2);
    left: 25px;
  }
  .mobile-nav .navigation.tw .header p {
    padding: 20px 0;
    font-size: 0.875rem;
    line-height: 1.375;
  }
  .mobile-nav .navigation.tw > .item {
    max-height: calc(0.875rem * 1.43 + 20px * 2);
  }
  .mobile-nav .navigation.tw > .item p, .mobile-nav .navigation.tw > .item a {
    padding: 20px 32px;
    font-size: 0.875rem;
    line-height: 1.43;
  }
  .mobile-nav .navigation.tw > .item .arrow-btn, .mobile-nav .navigation.tw > .item .expand-switch {
    top: calc(0.875rem * 1.43 / 2 + 20px - 20px / 2);
    right: 32px;
  }
  .mobile-nav .navigation.tw > .item .sub-nav li a {
    padding-left: 42px;
    font-size: 0.875rem;
    line-height: 1.375;
  }
  .mobile-nav .navigation.tw > .item .sub-nav li a[target='_blank']:after {
    margin-left: 10px;
  }
  .mobile-nav .navigation.tw > .item.active {
    padding-bottom: 10px;
  }
  .mobile-nav .navigation.tw > .item.active p, .mobile-nav .navigation.tw > .item.active a {
    padding-bottom: 10px;
  }
  .mobile-nav .navigation.en {
    margin: 21px 14px 0;
  }
  .mobile-nav .navigation.en > .item {
    max-height: calc(1rem * 1.69 + 26px * 2);
  }
  .mobile-nav .navigation.en > .item p {
    padding: 26px 18px;
    transition: all .2s;
  }
  .mobile-nav .navigation.en > .item .expand-switch {
    top: calc(1rem * 1.69 / 2 + 26px - 20px / 2);
    right: 18px;
  }
  .mobile-nav .navigation.en > .item .sub-nav li {
    margin-bottom: 15px;
    padding-left: 28px;
    font-size: 0.875rem;
    line-height: 1.43;
  }
  .mobile-nav .navigation.en > .item.active p {
    padding: 15px 18px 12px;
  }
  .mobile-nav .navigation.en > .item.active .expand-switch {
    top: calc(1rem * 1.69 / 2 + 15px - 20px / 2);
  }
  .mobile-nav .navigation.en > .item.active:first-child p {
    padding: 26px 18px 12px;
  }
  .mobile-nav .navigation.en > .item.active:first-child .expand-switch {
    top: calc(1rem * 1.69 / 2 + 26px - 20px / 2);
  }
}

@media all and (max-width: 320px) {
  .index-link-color.en .ntpa {
    font-size: 0.4375rem;
  }
  .index-link-color.en .pf {
    font-size: 0.75rem;
  }
  .mobile-nav .navigation.tw {
    max-height: 370px;
    overflow-y: scroll;
  }
}

.hero {
  display: flex;
  align-items: center;
  position: relative;
  max-height: 300px;
  overflow: hidden;
}

.hero img {
  width: 100vw;
}

.hero .header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.hero .header h1 {
  margin-bottom: 50px;
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 1.875rem;
  letter-spacing: 5px;
  text-align: center;
  color: white;
}

@media all and (max-width: 768px) {
  .hero {
    max-height: 266px;
  }
  .hero .header h1 {
    margin-bottom: 0;
  }
}

@media all and (max-width: 480px) {
  .hero {
    max-height: 188px;
  }
  .hero .header h1 {
    font-size: 1.3125rem;
    letter-spacing: 3px;
  }
}

footer {
  background-color: #a31d11;
}

footer .branch-container {
  display: flex;
  padding: 40px 0;
}

footer .branch-container > .item p {
  display: block;
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.69;
  letter-spacing: 1px;
  color: white;
}

footer .branch-container > .item ul {
  display: table;
  border-spacing: 0 7px;
  max-width: 300px;
}

footer .branch-container > .item ul li {
  display: table-row;
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: left;
  color: white;
}

footer .branch-container > .item ul li:before {
  content: url(../../assets/img/icon/icon-arrow.svg);
  display: table-cell;
  padding-right: 6px;
}

footer .branch-container > .item ul li:not(:last-child) {
  padding-bottom: 7px;
}

footer .branch-container > .item:not(:last-child) {
  margin-right: 37px;
}

footer .lang-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 40px 0;
}

footer .lang-container .more-on-fb {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

footer .lang-container .more-on-fb p {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: left;
  color: white;
}

footer .lang-container .more-on-fb .fb-icon {
  margin-left: 8px;
  width: 30px;
  height: 30px;
  background-image: url(../../assets/img/icon/btn-fb.svg);
  background-size: 30px 30px;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .2s;
}

footer .lang-container .more-on-fb .fb-icon:after {
  content: "";
  display: none;
  background-image: url("../../assets/img/icon/btn-fb-hover.svg");
}

footer .lang-container .more-on-fb:hover .fb-icon {
  background-image: url(../../assets/img/icon/btn-fb-hover.svg);
}

footer .lang-container .lang {
  display: flex;
}

footer .lang-container .lang li {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.875rem;
  line-height: 1.43;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
}

footer .lang-container .lang li a {
  font: inherit;
  font-weight: normal;
  color: inherit;
}

footer .lang-container .lang li:nth-child(1):after {
  content: '/';
  display: inline-block;
  margin: 0 10px 0 8px;
}

footer .bottom-wrapper {
  background-color: #4b0903;
}

footer .bottom-wrapper .info-wrapper {
  padding: 24px 0;
  text-align: center;
}

footer .bottom-wrapper .info-wrapper a, footer .bottom-wrapper .info-wrapper .divider, footer .bottom-wrapper .info-wrapper p {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: white;
}

footer .bottom-wrapper .info-wrapper p {
  margin-top: 15px;
  opacity: .5;
}

footer .bottom-wrapper .info-wrapper p br {
  display: none;
}

@media all and (max-width: 768px) {
  footer .grid > [class*='col'].branch-wrapper {
    flex-basis: 100%;
    max-width: 100%;
  }
  footer .grid > [class*='col'].lang-wrapper {
    display: none;
    flex-basis: 0;
    max-width: 0;
  }
  footer .branch-container {
    flex-direction: column;
    padding: 33px 30px;
  }
  footer .branch-container > .item ul {
    max-width: 100%;
  }
  footer .branch-container > .item:not(:last-child) {
    margin-bottom: 30px;
    margin-right: 0;
  }
}

@media all and (max-width: 480px) {
  footer .branch-container {
    padding: 33px 0;
  }
  footer .branch-container > .item p span {
    display: block;
    font-size: 0;
  }
  footer .branch-container > .item ul {
    max-width: 300px;
  }
  footer .bottom-wrapper {
    background-color: #4b0903;
  }
  footer .bottom-wrapper .info-wrapper p {
    margin-top: 10px;
  }
  footer .bottom-wrapper .info-wrapper p br {
    display: block;
  }
  footer .bottom-wrapper .info-wrapper.en {
    text-align: left;
  }
  footer .bottom-wrapper .info-wrapper.en a {
    display: block;
  }
  footer .bottom-wrapper .info-wrapper.en a:not(:first-child) {
    margin-top: 12px;
  }
  footer .bottom-wrapper .info-wrapper.en .divider {
    display: none;
  }
  footer .bottom-wrapper .info-wrapper.en p br {
    display: none;
  }
}

.hero-home {
  height: 30.6944444444vw;
  max-height: 442px;
}

.hero-home .img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30.6944444444vw;
  max-height: 442px;
  overflow: hidden;
}

.logo-bar {
  top: 60px;
}

.index-link {
  display: inline-flex;
}

.index-link .logo {
  margin-right: 17px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
}

.index-link .text {
  display: flex;
  flex-direction: column;
}

.index-link .ntpa {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.index-link .pf {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.index-link.tw .logo {
  height: 68px;
}

.index-link.tw .ntpa {
  font-size: 1.3125rem;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: normal;
}

.index-link.tw .pf {
  font-size: 2.3125rem;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: normal;
}

.index-link.en .ntpa {
  font-size: 0.75rem;
  letter-spacing: 1px;
}

.index-link.en .pf {
  font-size: 1.3125rem;
  font-weight: bold;
  line-height: 1.57;
}

.lang-switch {
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.lang-switch > *:not(:last-child) {
  margin-right: 13px;
}

.lang-btn {
  width: 50px;
  height: 50px;
  line-height: 1.5;
  border-radius: 50%;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: white;
  color: #a31d11;
  transition: all .2s;
}

.lang-btn.tw {
  font-weight: 600;
}

.lang-btn.en {
  font-weight: bold;
}

.lang-btn.active {
  cursor: default;
  background-color: #a31d11;
  color: white;
}

.lang-btn:not(.active):hover {
  background-color: #f4f4f4;
}

.index-nav .normal-nav.not-fixed {
  position: absolute;
  top: auto;
}

.index-nav .normal-nav.not-fixed .index-link-color {
  opacity: 0;
  visibility: hidden;
}

.index-nav .normal-nav.not-fixed .navigation.tw > .item {
  margin: 0 calc((100% - 105px * 7 - 20px * 2) / (7 * 2 - 2));
  min-width: 105px;
}

.index-nav .normal-nav.not-fixed .navigation.tw > .item:first-child {
  margin-left: 20px;
}

.index-nav .normal-nav.not-fixed .navigation.tw > .item:last-child {
  margin-right: 20px;
}

.index-nav .normal-nav.not-fixed .navigation.en > .item {
  margin: 0 55px;
}

.index .first-row-up {
  margin-top: 5.3333333333%;
}

.index .first-row-up .aspect-ratio-container {
  position: relative;
  padding-bottom: 52.5449101796%;
  height: 0;
}

.index .first-row-up .aspect-ratio-container .picture-container {
  height: 100%;
}

.index .first-row-up .aspect-ratio-container .img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.index .first-row-up .aspect-ratio-container .img-container img {
  width: 83.3333333333%;
  margin-left: 8.3333333333%;
}

.index .first-row-down {
  margin-bottom: 4.5833333333%;
}

.index .first-row-down .description-wrapper {
  position: relative;
  margin-top: -39.7887323944%;
  box-shadow: 0 4px 10px 0 rgba(148, 148, 148, 0.86);
  overflow: hidden;
  animation: shadow .2s ease 1s both;
}

@keyframes shadow {
  0% {
    box-shadow: none;
  }
  100% {
    box-shadow: 0 4px 10px 0 rgba(148, 148, 148, 0.86);
  }
}

.index .first-row-down .description {
  position: relative;
  padding: 20px 28px 22px;
  background-color: #a31d11;
  opacity: 0;
}

.index .first-row-down .description p {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 1rem;
  line-height: 1.69;
  letter-spacing: 1px;
  color: white;
  opacity: 0;
}

.index .first-row-down .description.js-is-in-screen {
  animation: description-slideUp .5s ease .2s both;
}

.index .first-row-down .description.js-is-in-screen p {
  animation: description-slideUp .5s ease .7s both;
}

@keyframes description-slideUp {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: .9;
  }
}

@keyframes description-p-slideUp {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.index .second-row {
  margin-bottom: 4.5833333333%;
}

.index .second-row.info-container .item {
  display: block;
  height: 0;
  padding-bottom: 62.7717391304%;
}

.index .info-header {
  margin-bottom: 1%;
  font-family: "Spectral SC", serif;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.18;
  text-align: left;
  color: #4b0903;
}

.index .index-top-btn-container {
  margin: 55px 0 44px;
  text-align: center;
}

.index .index-top-btn-container .index-top-btn {
  width: 55px;
  height: 55px;
  background-image: url(../../assets/img/icon/btn-top.svg);
  background-size: 55px 55px;
  background-position: center;
  background-repeat: no-repeat;
}

.index .tw-second-row h2, .index .tw-third-row h2 {
  position: relative;
  margin-bottom: 16px;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.18;
  text-align: left;
  color: #4b0903;
}

.index .tw-second-row h2 span, .index .tw-third-row h2 span {
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Spectral SC", serif;
  font-weight: bold;
  text-align: right;
  color: #e6cbc5;
}

.index .tw-second-row .info-container {
  display: flex;
  flex-wrap: wrap;
  margin: -4px -4px 50px;
}

.index .tw-second-row .info-container .item {
  flex: 0 0;
  flex-basis: calc((100% - 16px) / 2);
  margin: 3.9px;
}

.index .tw-second-row .info-container .item .aspect-ratio-container {
  height: 0;
  padding-bottom: 100%;
}

.index .third-row .info-container .item {
  display: block;
  height: 0;
  padding-bottom: 23.2020547945%;
}

.index .info-container .item {
  position: relative;
  overflow: hidden;
}

.index .info-container .item .img-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(0);
  transition: all .24s ease-in-out;
}

.index .info-container .item .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.index .info-container .item .text-container p {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 1.3125rem;
  font-weight: bold;
  line-height: 1.19;
  text-align: center;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.index .info-container .item .text-container.facebook-link p {
  margin-bottom: 2.5684931507%;
  font-size: 1.75rem;
  letter-spacing: 2px;
}

.index .info-container .item .text-container.facebook-link .fb-icon {
  width: 70px;
  height: 70px;
  background-image: url("../../assets/img/icon/btn-fb.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .2s;
}

.index .info-container .item .text-container.facebook-link .fb-icon:after {
  content: "";
  display: none;
  background-image: url("../../assets/img/icon/btn-fb-hover.svg");
}

.index .info-container .item .text-container.facebook-link.tw p {
  font-family: "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
}

.index .info-container .item .text-container.facebook-link.en p {
  font-family: "Spectral SC", serif;
}

.index .info-container .item:hover .img-container {
  transform: translateZ(0) scale(1.08);
}

.index .info-container .item:hover .text-container.facebook-link .fb-icon {
  background-image: url("../../assets/img/icon/btn-fb-hover.svg");
}

.index .news-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 50px;
  padding: 2.245508982% 0 10.4790419162%;
  border-top: solid 5px #a31d11;
  background-color: white;
}

.index .news-container > .item {
  display: flex;
  align-items: center;
  padding: 10px 33px;
  transition: all .2s;
}

.index .news-container > .item .date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 60px;
  background-color: #a39494;
}

.index .news-container > .item .date-container .month {
  font-family: "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  color: white;
}

.index .news-container > .item .date-container .day {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  text-align: center;
  color: white;
}

.index .news-container > .item .date-container .year {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  color: white;
}

.index .news-container > .item .date-container {
  flex: 0 0 55px;
  margin-right: 25px;
  height: 55px;
}

.index .news-container > .item .content {
  max-width: calc(100% - 80px);
}

.index .news-container > .item .content .header {
  display: flex;
}

.index .news-container > .item .content .header h3 {
  font-family: "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 1px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #3c3d3e;
}

.index .news-container > .item:hover {
  background-color: rgba(230, 203, 197, 0.4);
}

.index .news-container > .item.new .content .header:after {
  content: 'New!';
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 6px;
  padding: 2px 6px;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #cd5d67;
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: center;
  color: white;
}

.index .news-container .more {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-bottom: 4.4910179641%;
  transform: translateX(-50%);
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  color: #a31d11;
}

.index .news-container .more:after {
  content: '';
  display: block;
  position: absolute;
  margin-top: 5px;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  background-color: #a31d11;
  transition: all .2s;
}

.index .news-container .more:hover:after {
  transform: scaleX(1);
}

.index .speech-container {
  display: flex;
  flex-wrap: wrap;
  margin: -15px -15px 50px;
}

.index .speech-container > .item {
  flex: 0 0;
  flex-basis: calc((100% - 15px * 6) / 3);
  position: relative;
  margin: 15px;
  background-color: white;
}

.index .speech-container > .item .img-wrapper {
  position: relative;
  padding-bottom: 72.5543478261%;
  height: 0;
  overflow: hidden;
}

.index .speech-container > .item .img-wrapper .img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all .24s ease-in-out;
}

.index .speech-container > .item .img-wrapper .img-container .js-parallax {
  object-fit: contain;
}

.index .speech-container > .item .content-container {
  position: relative;
  transform: translateZ(0);
  margin-top: -9.7826086957%;
  padding: 0 9.5108695652% calc(6.7934782609% + 1.5rem);
  z-index: 1;
}

.index .speech-container > .item .content-container .date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 60px;
  background-color: #a39494;
}

.index .speech-container > .item .content-container .date-container .month {
  font-family: "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  color: white;
}

.index .speech-container > .item .content-container .date-container .day {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  text-align: center;
  color: white;
}

.index .speech-container > .item .content-container .date-container .year {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  color: white;
}

.index .speech-container > .item .content-container .date-container.red {
  width: 66px;
  height: 72px;
  background-color: #a31d11;
  box-shadow: 0 2px 3px 0 rgba(75, 9, 3, 0.49);
}

.index .speech-container > .item .content-container .date-container.red .day {
  font-size: 1.625rem;
}

.index .speech-container > .item .content-container .speaker {
  margin-top: 3.355704698%;
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: #a31d11;
}

.index .speech-container > .item .content-container .title {
  margin-top: 2.6845637584%;
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: left;
  color: #4b0903;
}

.index .speech-container > .item .content-container .time {
  margin-top: 3.0201342282%;
}

.index .speech-container > .item .content-container .time p {
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: #3c3d3e;
}

.index .speech-container > .item .content-container .time .table-row-style .decoration {
  padding-right: 8px;
}

.index .speech-container > .item .content-container .time .table-row-style .decoration:before {
  content: '';
  display: block;
  position: relative;
  top: calc(1em * (1.375 - 1) / 2);
  width: 15px;
  height: 15px;
  background-image: url("../../assets/img/icon/icon-time.svg");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
}

.index .speech-container > .item .content-container .location {
  margin-top: 3.0201342282%;
  margin-bottom: 4.6979865772%;
}

.index .speech-container > .item .content-container .location p {
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: #3c3d3e;
}

.index .speech-container > .item .content-container .location .table-row-style .decoration {
  padding-right: 8px;
}

.index .speech-container > .item .content-container .location .table-row-style .decoration:before {
  content: '';
  display: block;
  position: relative;
  top: calc(1em * (1.375 - 1) / 2);
  width: 15px;
  height: 15px;
  background-image: url("../../assets/img/icon/icon-location.svg");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
}

.index .speech-container > .item .more {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-bottom: 6.7934782609%;
  transform: translateX(-50%);
  font-family: "Open Sans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  color: #a31d11;
}

.index .speech-container > .item .more:after {
  content: '';
  display: block;
  position: absolute;
  margin-top: 5px;
  width: 100%;
  height: 2px;
  transform: translateZ(0) scaleX(0);
  background-color: #a31d11;
  transition: all .2s;
}

.index .speech-container > .item:hover .img-wrapper .img-container {
  transform: scale(1.08);
}

.index .speech-container > .item:hover .more:after {
  transform: translateZ(0) scaleX(1);
}

@media all and (max-width: 768px) {
  .hero-home {
    height: 46.2239583333vw;
    max-height: 355px;
  }
  .hero-home .img-container {
    height: 46.2239583333vw;
    max-height: 355px;
  }
  .logo-bar {
    top: 38px;
  }
  .index-link {
    display: none;
  }
  .index-nav {
    padding-bottom: 0;
  }
  .index-nav .normal-nav {
    background-color: rgba(255, 255, 255, 0.8);
    transition: all .5s;
  }
  .index-nav .normal-nav.show {
    background-color: white;
  }
  .index-nav .normal-nav.not-fixed {
    position: fixed;
    top: 0;
  }
  .index-nav .normal-nav.not-fixed .index-link-color {
    opacity: 1;
    visibility: visible;
  }
  .lang-switch {
    display: none;
  }
  .index .first-row-up {
    margin-top: 2.8846153846%;
  }
  .index .first-row-up.grid > [class*='col']:first-child {
    flex-basis: 72.8021978022%;
    max-width: 72.8021978022%;
  }
  .index .first-row-up .aspect-ratio-container {
    padding-bottom: 61.6981132075%;
  }
  .index .first-row-down {
    margin-bottom: 2.6098901099%;
  }
  .index .first-row-down.grid > [class*='col']:first-child {
    flex-basis: 71.4285714286%;
    max-width: 71.4285714286%;
    margin-left: 28.5714285714%;
  }
  .index .first-row-down .description-wrapper {
    margin-top: -21.9230769231%;
    box-shadow: none;
    animation: none;
  }
  .index .first-row-down .description {
    padding: 18px 18px 13px 23px;
  }
  .index .second-row {
    margin-bottom: 4.8076923077%;
  }
  .index .second-row.grid > [class*='col']:nth-child(2) {
    padding-right: 7.5px;
  }
  .index .second-row.grid > [class*='col']:nth-child(3) {
    padding-left: 7.5px;
  }
  .index .second-row.info-container .item {
    padding-bottom: 55.7939914163%;
  }
  .index .tw-second-row.grid > [class*='col'] {
    flex-basis: 100%;
    max-width: 100%;
  }
  .index .tw-second-row .news-container {
    margin-bottom: 40px;
  }
  .index .tw-second-row .news-container > .item:hover {
    background-color: transparent;
  }
  .index .tw-second-row .news-container > .item:hover:active {
    background-color: rgba(230, 203, 197, 0.4);
  }
  .index .tw-second-row .news-container .more:hover:after {
    transform: scaleX(0);
  }
  .index .tw-second-row .news-container .more:hover:active:after {
    transform: scaleX(1);
  }
  .index .tw-second-row .info-container {
    margin: -7px -7px 40px;
  }
  .index .tw-second-row .info-container > .item:nth-child(1) {
    flex-basis: calc(100% - 7px * 2);
    margin: 7px;
  }
  .index .tw-second-row .info-container > .item:nth-child(1) .aspect-ratio-container {
    padding-bottom: 25.5845942228%;
  }
  .index .tw-second-row .info-container > .item:nth-child(n + 2) {
    flex-basis: calc((100% - 7px * 6) / 3);
    margin: 7px;
  }
  .index .tw-second-row .info-container > .item:nth-child(n + 2) .aspect-ratio-container {
    padding-bottom: 55.7939914163%;
  }
  .index .tw-third-row .speech-container {
    margin: -5px -5px 40px;
  }
  .index .tw-third-row .speech-container > .item {
    flex-basis: calc((100% - 5px * 4) / 2);
    margin: 5px;
  }
  .index .tw-third-row .speech-container > .item:nth-child(n + 3) {
    display: none;
  }
  .index .tw-third-row .speech-container > .item:hover .img-wrapper .img-container {
    transform: scale(1);
  }
  .index .tw-third-row .speech-container > .item:hover .more:after {
    transform: translateZ(0) scaleX(0);
  }
  .index .tw-third-row .speech-container > .item:hover:active .img-wrapper .img-container {
    transform: scale(1.08);
  }
  .index .tw-third-row .speech-container > .item:hover:active .more:after {
    transform: translateZ(0) scaleX(1);
  }
  .index .third-row .info-container .item {
    padding-bottom: 28.1593406593%;
  }
  .index .index-top-btn-container {
    margin: 30px 0;
  }
  .index .info-container .item .text-container.facebook-link p {
    margin-bottom: 2.8846153846%;
    font-size: 1.3125rem;
    letter-spacing: 4px;
    text-indent: 4px;
  }
  .index .info-container .item:hover .img-container {
    transform: translateZ(0);
  }
  .index .info-container .item:hover .text-container.facebook-link .fb-icon {
    background-image: url("../../assets/img/icon/btn-fb.svg");
  }
  .index .info-container .item:hover:active .img-container {
    transform: translateZ(0) scale(1.08);
  }
  .index .info-container .item:hover:active .text-container.facebook-link .fb-icon {
    background-image: url("../../assets/img/icon/btn-fb-hover.svg");
  }
}

@media all and (max-width: 480px) {
  .hero-home {
    height: 68.2666666667vw;
    max-height: 256px;
  }
  .hero-home .img-container {
    height: 68.2666666667vw;
    max-height: 256px;
  }
  .logo-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    height: 100%;
  }
  .index .first-row-up {
    margin-top: 7.2886297376%;
  }
  .index .first-row-up.grid > [class*='col']:first-child {
    flex-basis: 100%;
    max-width: 100%;
  }
  .index .first-row-up .aspect-ratio-container {
    padding-bottom: 51.0204081633%;
  }
  .index .first-row-down {
    margin-bottom: 11.3702623907%;
  }
  .index .first-row-down.grid > [class*='col']:first-child {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
  }
  .index .first-row-down .description-wrapper {
    margin-top: 0;
  }
  .index .first-row-down .description {
    padding: 13px 15px 12px;
  }
  .index .first-row-down .description p {
    font-size: 0.875rem;
    line-height: 1.71;
  }
  .index .second-row {
    margin-bottom: 8.7463556851%;
  }
  .index .second-row.grid > [class*='col']:nth-child(2) {
    padding-right: 3.5px;
  }
  .index .second-row.grid > [class*='col']:nth-child(3) {
    padding-left: 3.5px;
  }
  .index .second-row.grid > [class*='col']:first-child {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 7px;
  }
  .index .second-row.info-container > *:nth-child(1) .item {
    padding-bottom: 29.9120234604%;
  }
  .index .second-row.info-container > *:nth-child(2) .item, .index .second-row.info-container > *:nth-child(3) .item {
    padding-bottom: 60.7142857143%;
  }
  .index .tw-second-row h2, .index .tw-third-row h2 {
    margin-bottom: 14px;
    font-size: 1.3125rem;
  }
  .index .tw-second-row .news-container {
    margin-bottom: 33px;
    padding: 2.915451895% 0 17.4927113703%;
  }
  .index .tw-second-row .news-container > .item {
    padding: 10px 13px;
  }
  .index .tw-second-row .news-container > .item .date-container {
    margin-right: 15px;
  }
  .index .tw-second-row .news-container > .item .content {
    max-width: calc(100% - 70px);
  }
  .index .tw-second-row .news-container > .item .content .header h3 {
    line-height: 1.25;
  }
  .index .tw-second-row .news-container > .item.new .content .header:after {
    font-size: 0.75rem;
  }
  .index .tw-second-row .info-container {
    margin: -3.5px -3.5px 33px;
  }
  .index .tw-second-row .info-container > .item:nth-child(n + 1) {
    flex-basis: calc((100% - 3.5px * 4) / 2);
    margin: 3.5px;
  }
  .index .tw-second-row .info-container > .item:nth-child(n + 1) .aspect-ratio-container {
    padding-bottom: 60.7142857143%;
  }
  .index .tw-third-row .speech-container {
    margin-bottom: 33px;
  }
  .index .tw-third-row .speech-container > .item {
    flex-basis: calc(100% - 5px * 2);
  }
  .index .tw-third-row .speech-container > .item .content-container {
    padding: 0 7.8717201166% 6.1224489796%;
  }
  .index .tw-third-row .speech-container > .item .content-container .location {
    margin-bottom: 0;
  }
  .index .tw-third-row .speech-container > .item .more {
    display: none;
  }
  .index .third-row .info-container .item {
    padding-bottom: 58.3090379009%;
  }
  .index .info-header {
    margin-bottom: 4.3731778426%;
    font-size: 1.3125rem;
    font-weight: 600;
    line-height: 1.19;
  }
  .index .index-top-btn-container {
    margin: 25px 0;
  }
  .index .index-top-btn-container .index-top-btn {
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
  }
}
