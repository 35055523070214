@mixin date-container {
    .date-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 60px;
        background-color: #a39494;

        .month {
            font-family: $font-family-primary;
            font-size: calc-rem(12px);
            font-weight: 600;
            text-align: center;
            color: white; }

        .day {
            font-family: $font-family-OpenSans;
            font-size: calc-rem(20px);
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 1;
            text-align: center;
            color: white; }

        .year {
            font-family: $font-family-OpenSans;
            font-size: calc-rem(12px);
            font-weight: 600;
            letter-spacing: 1px;
            text-align: center;
            color: white; } } }
