footer {
    background-color: $rusty-red;

    .branch-container {
        display: flex;
        padding: 40px 0;

        > .item {
            p {
                display: block;
                font-family: $font-family-OpenSans;
                font-size: calc-rem(16px);
                font-weight: bold;
                line-height: 1.69;
                letter-spacing: 1px;
                color: white; }

            ul {
                display: table;
                border-spacing: 0 7px;
                max-width: 300px;

                li {
                    display: table-row;
                    font-family: $font-family-OpenSans;
                    font-size: calc-rem(14px);
                    line-height: 1.43;
                    letter-spacing: 1px;
                    text-align: left;
                    color: white;

                    &:before {
                        content: url(../../assets/img/icon/icon-arrow.svg);
                        display: table-cell;
                        padding-right: 6px; } }

                li:not(:last-child) {
                    padding-bottom: 7px; } } }

        > .item:not(:last-child) {
            margin-right: 37px; } }


    .lang-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 40px 0;

        .more-on-fb {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            p {
                font-family: $font-family-OpenSans;
                font-size: calc-rem(14px);
                line-height: 1.43;
                letter-spacing: 1px;
                text-align: left;
                color: white; }

            .fb-icon {
                margin-left: 8px;
                width: 30px;
                height: 30px;
                background-image: url(../../assets/img/icon/btn-fb.svg);
                background-size: 30px 30px;
                background-position: center;
                background-repeat: no-repeat;
                transition: all .2s;
                @include preload-image('../../assets/img/icon/btn-fb-hover.svg'); } }

        .more-on-fb:hover {
            .fb-icon {
                background-image: url(../../assets/img/icon/btn-fb-hover.svg); } }

        .lang {
            display: flex;

            li {
                font-family: $font-family-OpenSans;
                font-size: calc-rem(14px);
                line-height: 1.43;
                font-weight: bold;
                letter-spacing: 1px;
                color: white;

                a {
                    font: inherit;
                    font-weight: normal;
                    color: inherit; } }

            li:nth-child(1) {
                &:after {
                    content: '/';
                    display: inline-block;
                    margin: 0 10px 0 8px; } } } }

    .bottom-wrapper {
        background-color: $dried-blood;

        .info-wrapper {
            padding: 24px 0;
            text-align: center;

            a, .divider, p {
                font-family: $font-family-OpenSans;
                font-size: calc-rem(14px);
                letter-spacing: 1px;
                color: white; }

            p {
                margin-top: 15px;
                opacity: .5;

                br {
                    display: none; } } } } }

@media all and (max-width: $tablet) {
    footer {
        .grid > [class*='col'].branch-wrapper {
            flex-basis: 100%;
            max-width: 100%; }

        .grid > [class*='col'].lang-wrapper {
            display: none;
            flex-basis: 0;
            max-width: 0; }

        .branch-container {
            flex-direction: column;
            padding: 33px 30px;

            > .item {
                ul {
                    max-width: 100%; } }

            > .item:not(:last-child) {
                margin-bottom: 30px;
                margin-right: 0; } } } }

@media all and (max-width: $mobile) {
    footer {
        .branch-container {
            padding: 33px 0;

            > .item {
                p {
                    span {
                        display: block;
                        font-size: 0; } }

                ul {
                    max-width: 300px; } } }

        .bottom-wrapper {
            background-color: $dried-blood;

            .info-wrapper {
                p {
                    margin-top: 10px;

                    br {
                        display: block; } } }

            .info-wrapper.en {
                text-align: left;

                a {
                    display: block;

                    &:not(:first-child) {
                        margin-top: 12px; } }

                .divider {
                    display: none; }

                p {
                    br {
                        display: none; } } } } } }
