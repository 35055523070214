.index-link-color {
    display: inline-flex;
    align-items: center;
    transition: .5s ease-out;

    .logo {
        margin-right: 14px;
        width: 72px;
        height: 48px;
        filter: none; }

    .text {
        display: flex;
        flex-direction: column; }

    .ntpa, .pf {
        font-family: $font-family-OpenSans;
        color: $dark-grey-blue;
        text-shadow: none; } }

.index-link-color.en {
    .ntpa {
        font-size: calc-rem(12px);
        letter-spacing: 1px; }

    .pf {
        font-size: calc-rem(21px);
        font-weight: bold; } }

.index-link-color.tw {
    .ntpa {
        font-size: calc-rem(12px);
        font-weight: 600;
        letter-spacing: 1px; }

    .pf {
        font-size: calc-rem(28px);
        font-weight: 600;
        line-height: 1.18; } }

.normal-nav-wrapper {
    padding-bottom: 96px; }

.normal-nav {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 1000;

    .index-link-color {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }

    .navigation {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        > .item {
            position: relative;
            margin: 0 20px;
            padding: 37px 0;
            min-width: 68px;
            transition: all .5s cubic-bezier(0.22, 0.61, 0.36, 1);
            cursor: default;

            p {
                font-family: $font-family-OpenSans;
                font-size: calc-rem(16px);
                letter-spacing: 1px;
                text-align: center;
                color: $charcoal-grey; }

            .sub-nav {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 100%);
                padding: 16px 0;
                min-width: calc(105px * 1.18);
                opacity: 0;
                visibility: hidden;
                z-index: -1;
                transition: all .6s cubic-bezier(.075,.82,.165,1), z-index 0s;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    transform: scale(.9);
                    width: 100%;
                    background-color: white;
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
                    opacity: 0;
                    z-index: -1;
                    transition: all .6s cubic-bezier(.075,.82,.165,1), z-index 0s;
                    transform-origin: top; }

                li {
                    padding: 10px 20px;
                    font-family: $font-family-OpenSans;
                    font-size: calc-rem(14px);
                    line-height: $normal-line-height;
                    letter-spacing: 1px;
                    text-align: center;
                    white-space: nowrap;
                    color: $charcoal-grey;
                    transition: all .2s;

                    a {
                        display: block;
                        font: inherit;
                        color: inherit; } }

                li:hover {
                    color: $rusty-red; } }

            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                transform: scaleX(0);
                height: 4px;
                background-color: $tomato;
                opacity: 0;
                visibility: hidden;
                transition: all .6s cubic-bezier(.075,.82,.165,1); } }

        > .item.active {
            &:after {
                bottom: 24px;
                left: 50%;
                transform: translateX(-50%) scaleX(1);
                width: 30px;
                opacity: 1;
                visibility: visible; } }

        > .item:hover {
            .sub-nav {
                opacity: 1;
                visibility: visible;

                &:after {
                    transform: scale(1);
                    opacity: 1; } }

            &:after {
                bottom: 0;
                left: 0;
                transform: scaleX(1.18);
                width: 100%;
                opacity: 1;
                visibility: visible; } } } }

.nav-wrapper {
    display: flex;
    align-items: center; }

@include hamburger-btn;
.hamburger-btn {
    display: none; }

.mobile-nav {
    display: none; }

.side-nav {
    .navigation {
        > .item {
            position: relative;
            max-height: calc(#{calc-rem(14px)} * #{$normal-line-height} + 23px * 2);
            overflow: hidden;
            transition: all .2s cubic-bezier(.075,.82,.165,1);

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background-color: $tomato; }

            > p, > a p {
                display: flex;
                align-items: center;
                padding: 0 25px;
                width: 100%;
                height: calc(#{calc-rem(14px)} * #{$normal-line-height} + 23px * 2);
                box-sizing: border-box;
                background-color: white;
                border: solid 1px #f7f7f7;
                font-family: $font-family-OpenSans;
                font-size: calc-rem(14px);
                letter-spacing: 1px;
                color: #333333;
                cursor: pointer;
                transition: all .2s; }

            .expand-arrow {
                position: absolute;
                top: calc(#{calc-rem(14px)} * #{$normal-line-height} / 2 + 23px - 15px / 2);
                right: 25px;
                width: 15px;
                height: 15px;
                background-image: url(../../assets/img/icon/icon-arrowdown.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                transition: all .2s;
                cursor: pointer;

                &.on {
                    transform: rotateX(-180deg); } }

            .sub-nav {
                li {
                    position: relative;
                    background-color: #e2e2e2;
                    border: solid 1px #f8f8f8;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 7px;
                        height: 100%;
                        transform: scaleY(0);
                        background-color: $tomato;
                        transition: all .2s; }

                    > a p {
                        display: block;
                        padding: 15px 15px 15px 45px;
                        font-family: $font-family-OpenSans;
                        font-size: calc-rem(14px);
                        letter-spacing: 1px;
                        color: rgba(51, 51, 51, 0.6); }

                    > a[target='_blank'] p {
                        &:after {
                            content: url('../../assets/img/icon/icon-link.svg');
                            display: inline-block;
                            position: relative;
                            top: calc(#{calc-rem(14px)} * #{$normal-line-height} - 16px);
                            left: 6px;
                            width: 16px;
                            height: 16px; } } }

                li:hover {
                    &:before {
                        transform: scaleY(1); } } } }

        > .item.active {
            &:before {
                width: 7px; }

            > a p {
                font-weight: bold; } }

        > .item.expand {
            max-height: 500px;
            transition: all .2s cubic-bezier(0.71, 0.01, 1, 0.32);

            &:before {
                width: 0; }

            > p {
                font-weight: 600; }

            .sub-nav {
                li.active {
                    &:before {
                        transform: scaleY(1); }

                    > a p {
                        color: rgba(51, 51, 51, 1); } } } } } }

@media all and (max-width: $tablet) {
    .nav-wrapper {
        height: 91px; }

    .index-link-color {
        margin-left: 4px;

        .logo {
            margin-right: 11px;
            width: 72px;
            height: 48px; } }

    .index-link-color.tw {
        .pf {
            font-size: calc-rem(20px);
            line-height: $normal-line-height; } }

    .normal-nav-wrapper {
        padding-bottom: 91px; }

    .normal-nav {
        .navigation {
            display: none; } }

    .hamburger-btn {
        display: block;
        margin-left: auto;
        margin-right: 10px; }

    .mobile-nav {
        display: block;

        .container {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100vw;
            background-color: rgba(0, 0, 0, .45);
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            z-index: 1001;
            transition: all .3s cubic-bezier(.075,.82,.165,1), z-index 0s;
            transform-origin: right;

            .drawer {
                display: flex;
                justify-content: flex-end;
                margin-right: -71px - 481px;
                height: 100%;
                transition: all .6s cubic-bezier(.075,.82,.165,1);

                @include nav-close-btn;

                .panel {
                    position: relative;
                    width: 481px;
                    height: 100%;
                    background-color: $rusty-red;

                    .lang-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        position: absolute;
                        left: 0;
                        bottom: 46px;
                        padding: 0 62px;
                        width: 100%;

                        .more-on-fb {
                            display: flex;
                            align-items: center;

                            p {
                                font-family: $font-family-OpenSans;
                                font-size: calc-rem(14px);
                                line-height: 1.43;
                                letter-spacing: 1px;
                                text-align: left;
                                color: white; }

                            .fb-icon {
                                margin-left: 8px;
                                width: 30px;
                                height: 30px;
                                background-image: url(../../assets/img/icon/btn-fb.svg);
                                background-size: 30px 30px;
                                background-position: center;
                                background-repeat: no-repeat;
                                transition: all .2s;
                                @include preload-image('../../assets/img/icon/btn-fb-hover.svg'); } }

                        .more-on-fb:hover:active {
                            .fb-icon {
                                background-image: url(../../assets/img/icon/btn-fb-hover.svg); } }

                        .lang {
                            display: flex;

                            li {
                                font-family: $font-family-OpenSans;
                                font-size: calc-rem(14px);
                                line-height: 1.43;
                                font-weight: bold;
                                letter-spacing: 1px;
                                color: white;

                                a {
                                    font: inherit;
                                    font-weight: normal;
                                    color: inherit; } }

                            li:nth-child(1) {
                                &:after {
                                    content: '/';
                                    display: inline-block;
                                    margin: 0 10px 0 8px; } } } } } } }
        @include expand-switch;
        .navigation.tw {
            @include arrow-btn;

            .header {
                position: relative;
                background-color: rgba(75, 9, 3, 0.2);

                .arrow-btn {
                    position: absolute;
                    top: calc(#{calc-rem(16px)} * 1.5 / 2 + 25px - 20px / 2);
                    left: 37px;
                    margin: -10px;
                    padding: 20px; }

                p {
                    padding: 28px 0;
                    font-size: calc-rem(16px);
                    line-height: 1.5;
                    text-align: center;
                    color: white; } }

            > .item {
                position: relative;
                max-height: calc(#{calc-rem(16px)} * 1.88 + 25px * 2);
                overflow: hidden;
                transition: all .2s cubic-bezier(.075,.82,.165,1);

                p, a {
                    display: block;
                    padding: 25px 62px;
                    font-size: calc-rem(16px);
                    line-height: 1.88;
                    color: white;
                    transition: all .2s; }

                .arrow-btn, .expand-switch {
                    position: absolute;
                    top: calc(#{calc-rem(16px)} * 1.88 / 2 + 25px - 20px / 2);
                    right: 62px; }

                .sub-nav {
                    li {
                        a {
                            display: block;
                            padding: 10px 10px 10px 76px;
                            font-size: calc-rem(16px);
                            line-height: 1.88;
                            color: white; }

                        a[target='_blank'] {
                            &:after {
                                content: url('../../assets/img/icon/icon-link-white.svg');
                                display: inline-block;
                                position: relative;
                                top: 3px;
                                margin-left: 24px;
                                width: 16px;
                                height: 16px; } } } } }

            > .item.active {
                padding-bottom: 15px;
                max-height: 500px;
                transition: all .2s cubic-bezier(0.71, 0.01, 1, 0.32);

                p, a {
                    padding-bottom: 15px; } }

            > .item:not(:first-child) {
                    border-top: solid 1px rgba(255, 255, 255, 0.5); }

            .header + .item {
                border-top: none; } }

        .navigation.tw.slide-nav {
            position: absolute;
            top: 0;
            right: -100%;
            bottom: 0;
            left: 100%;
            background-color: $rusty-red;
            transition: all .6s cubic-bezier(.075,.82,.165,1); }

        .navigation.tw.slide-nav.active {
            right: 0;
            left: 0; }

        .navigation.en {
            margin: 75px 62px 0;

            > .item {
                position: relative;
                max-height: calc(#{calc-rem(16px)} * 1.69 + 25px * 2);
                overflow: hidden;
                transition: all .2s cubic-bezier(.075,.82,.165,1);

                p {
                    padding: 25px 12px;
                    font-family: $font-family-OpenSans;
                    font-size: calc-rem(16px);
                    font-weight: bold;
                    line-height: 1.69;
                    letter-spacing: 1px;
                    color: white; }

                .expand-switch {
                    position: absolute;
                    top: calc(#{calc-rem(16px)} * 1.69 / 2 + 25px - 20px / 2);
                    right: 12px; }

                .sub-nav {
                    li {
                        margin-bottom: 25px;
                        padding-left: 30px;
                        font-family: $font-family-OpenSans;
                        font-size: calc-rem(16px);
                        line-height: 1.69;
                        letter-spacing: 1px;
                        color: white;

                        a {
                            display: block;
                            font: inherit;
                            color: inherit; } } } }

            > .item.active {
                max-height: 500px;
                transition: all .2s cubic-bezier(0.71, 0.01, 1, 0.32); }

            > .item:not(:first-child) {
                border-top: solid 1px white; } } }

    .mobile-nav.active {
        .container {
            opacity: 1;
            visibility: visible;

            .drawer {
                margin-right: 0; } } }

    .side-nav {
        display: none; } }

@media all and (max-width: $mobile) {
    .nav-wrapper {
        height: 55px; }

    .index-link-color {
        .logo {
            margin-right: 8px;
            width: 45px;
            height: 30px; } }

    .index-link-color.en {
        .ntpa {
            font-size: calc-rem(8px);
            letter-spacing: .5px; }

        .pf {
            font-size: calc-rem(14px); } }

    .index-link-color.tw {
        .ntpa {
            font-size: calc-rem(10px); }

        .pf {
            font-size: calc-rem(16px); } }

    .normal-nav-wrapper {
        padding-bottom: 55px; }

    .hamburger-btn {
        margin-right: 0; }

    .mobile-nav {
        .container {
            .drawer {
                margin-right: -71px - 255px;

                .panel {
                    width: 255px;

                    .lang-container {
                        bottom: 31px;
                        padding: 0 21px 0 32px;

                        .more-on-fb {
                            p {
                                display: none; }

                            .fb-icon {
                                margin-left: 0; } } } }

                .panel.mobile-tw-width {
                    width: 289px; } } }

        .navigation.tw {
            .header {
                .arrow-btn {
                    top: calc(#{calc-rem(14px)} * #{$normal-line-height} / 2 + 20px - 20px / 2);
                    left: 25px; }

                p {
                    padding: 20px 0;
                    font-size: calc-rem(14px);
                    line-height: $normal-line-height; } }

            > .item {
                max-height: calc(#{calc-rem(14px)} * 1.43 + 20px * 2);

                p, a {
                    padding: 20px 32px;
                    font-size: calc-rem(14px);
                    line-height: 1.43; }

                .arrow-btn, .expand-switch {
                    top: calc(#{calc-rem(14px)} * 1.43 / 2 + 20px - 20px / 2);
                    right: 32px; }

                .sub-nav {
                    li {
                        a {
                            padding-left: 42px;
                            font-size: calc-rem(14px);
                            line-height: $normal-line-height; }

                        a[target='_blank'] {
                            &:after {
                                margin-left: 10px; } } } } }

            > .item.active {
                padding-bottom: 10px;

                p, a {
                    padding-bottom: 10px; } } }

        .navigation.en {
            margin: 21px 14px 0;

            > .item {
                max-height: calc(#{calc-rem(16px)} * 1.69 + 26px * 2);

                p {
                    padding: 26px 18px;
                    transition: all .2s; }

                .expand-switch {
                    top: calc(#{calc-rem(16px)} * 1.69 / 2 + 26px - 20px / 2);
                    right: 18px; }

                .sub-nav {
                    li {
                        margin-bottom: 15px;
                        padding-left: 28px;
                        font-size: calc-rem(14px);
                        line-height: 1.43; } } }

            > .item.active {
                p {
                    padding: 15px 18px 12px; }

                .expand-switch {
                    top: calc(#{calc-rem(16px)} * 1.69 / 2 + 15px - 20px / 2); } }

            > .item.active:first-child {
                p {
                    padding: 26px 18px 12px; }

                .expand-switch {
                    top: calc(#{calc-rem(16px)} * 1.69 / 2 + 26px - 20px / 2); } } } } }

@media all and (max-width: 320px) {
    .index-link-color.en {
        .ntpa {
            font-size: calc-rem(7px); }

        .pf {
            font-size: calc-rem(12px); } }

    .mobile-nav {
        .navigation.tw {
            max-height: 370px;
            overflow-y: scroll; } } }
